<template>
  <div class="p-3 listview card border-light">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-4">
        <div class="cell">
          <label>{{ $t('name') }}: </label>
          <span>{{ value.name || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('visibleToThePublic') }}: </label>
          <span>{{ $t(value.visibleForWebUser ? 'yes' : 'no')}}</span>
        </div>
        <div class="cell">
          <label>{{ $t('requireEmail') }}: </label>
          <span>{{ $t(value.isEmailRequired ? 'yes' : 'no')}}</span>
        </div>
        <div class="cell">
          <label>{{ $t('requirePhone') }}: </label>
          <span>{{ $t(value.isMobilePhoneRequired ? 'yes' : 'no')}}</span>
        </div>
        <div class="cell">
          <label>{{ $t('requireComment') }}: </label>
          <span>{{ $t(value.isCommentRequired ? 'yes' : 'no')}}</span>
        </div>

      </div>
      <div class="col-12 col-sm-12 col-md-4">
        <div class="cell">
          <label>{{ $t('warning') }}: </label>
          <span>{{ $t(value.warning ? 'yes' : 'no')}}</span>
        </div>
        <div class="cell">
          <label>{{ $t('hideComment') }}: </label>
          <span>{{ $t(value.hiddenComment ? 'yes' : 'no')}}</span>
        </div>
        <div class="cell">
          <label>{{ $t('youCanAttachDocument') }}: </label>
          <span>{{ $t(value.canAttachDocument ? 'yes' : 'no')}}</span>
        </div>
        <div class="cell">
          <label>{{ $t('filterspecialist') }}: </label>
          <span>{{ $t(value.filterAvailabilityBySpecialist ? 'yes' : 'no')}}</span>
        </div>
        <div class="cell">
          <label>{{ $t('inactive') }}: </label>
          <span>{{ $t(value.inactive ? 'yes' : 'no')}}</span>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-4">
        <action-button :actions="actions" :text="$t('actions')" />
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'RoleSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.$emit('edit'),
        }
      ],
    };
  }
};
</script>
<style lang="scss" scoped>
.listview .cell {
  clear: left;
}
.listview .cell label {
  float: left;
  font-weight: bold;
}
.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
.card {
  word-break: break-word;
}
</style>

