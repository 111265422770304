<template>
  <validation-observer ref="formRef" novalidate>
    <div class="row">
      <div class="col-md-12">
        <validation-provider v-slot="{ errors }" rules="required|max:200">
          <base-input
            v-model="model.name"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('name')"
          />
        </validation-provider>
      </div>
      <div class="col-md-4">
        <b-form-checkbox v-model="model.visibleForWebUser" switch>
          {{ $t('visibleToThePublic') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-4">
        <b-form-checkbox v-model="model.isEmailRequired" switch>
          {{ $t('requireEmail') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-4">
        <b-form-checkbox v-model="model.isMobilePhoneRequired" switch>
          {{ $t('requirePhone') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-4">
        <b-form-checkbox v-model="model.isCommentRequired" switch>
          {{ $t('requireComment') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-4">
        <b-form-checkbox v-model="model.warning" switch>
          {{ $t('warning') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-4">
        <b-form-checkbox v-model="model.hiddenComment" switch>
          {{ $t('hideComment') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-4">
        <b-form-checkbox v-model="model.filterAvailabilityBySpecialist" switch>
          {{ $t('filterspecialist') }}
        </b-form-checkbox>
      </div>
      <div v-if="model.id" class="col-md-4">
        <b-form-checkbox v-model="model.inactive" switch>
          {{ $t('inactive') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-12 mt-2">
        <base-input
          v-model="model.warningDescription"
          :textarea="true"
          :label="$t('warningMessage')"
        />
      </div>
      <div v-if="model.id" class="col-md-12 mb-4">
        <div class="row">
          <div class="col-md-4">
            <b-form-checkbox v-model="model.canAttachDocument" switch>
              {{ $t('youCanAttachDocument') }}
            </b-form-checkbox>
          </div>
          <div v-if="model.canAttachDocument" class="col-md-12 mt-2">
            <div class="row">
              <div class="col-md-6">
                <h4>{{ $t('documents') }}</h4>
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-success" @click="addDocument()">
                  {{ $t('addDocument') }}
                </button>
              </div>
            </div>
            <hr />
          </div>
          <div
            v-if="!model.documents?.length && model.canAttachDocument"
            class="col-md-12"
          >
            <h5 class="text-center">{{ $t('thereAreNoDocuments') }}</h5>
          </div>
          <div v-if="model.canAttachDocument" class="col-md-12">
            <div class="row">
              <div
                v-for="(document, i) in model.documents"
                :key="i"
                class="col-md-12"
              >
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <div class="col-md-9">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|max:64"
                        >
                          <base-input
                            v-model="document.documentName"
                            :error="errors[0]"
                            :error-msg="$t('invalidField')"
                            :label="$t('name')"
                          />
                        </validation-provider>
                      </div>
                      <div class="col-md-3 pt-4">
                        <b-form-checkbox
                          class="mt-2"
                          v-model="document.isRequired"
                          switch
                        >
                          {{ $t('isRequired') }}
                        </b-form-checkbox>
                      </div>
                      <div class="col-md-9">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|max:64"
                        >
                          <base-input
                            v-model="document.documentDescription"
                            :error="errors[0]"
                            :error-msg="$t('invalidField')"
                            :label="$t('description')"
                          />
                        </validation-provider>
                      </div>
                      <div class="col-md-3 pt-4">
                        <b-form-checkbox
                          class="mt-2"
                          v-model="document.isAutoDelete"
                          switch
                        >
                          {{ $t('autoDelete') }}
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <button
                      class="btn btn-danger btn-block"
                      style="height: 100%"
                      @click="removeDocument(i)"
                    >
                      <i class="far fa-trash-alt fa-2x"></i>
                    </button>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 text-right mt-3">
        <base-filled-button
          bg-color="#707070"
          class="mx-0 float-left"
          icon-class="fas fa-ban"
          :on-click="() => $emit('close')"
          :text="$t('cancel')"
        />

        <button
          class="btn btn-outline-primary"
          variant="outline-primary"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </validation-observer>
</template>
<script>
import BaseInput from '@/components/BaseInput';
import {
  addCodeServiceType as _addCodeServiceType,
  updateCodeServiceType as _updateCodeServiceType,
} from '@/services/ServicesService';

export default {
  name: 'CodeServiceTypeForm',
  components: {
    BaseInput,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      model: {
        id: 0,
        name: null,
        description: null,
        visibleForWebUser: false,
        warning: false,
        warningDescription: null,
        isMobilePhoneRequired: false,
        isCommentRequired: false,
        hiddenComment: false,
        canAttachDocument: false,
        isEmailRequired: false,
        filterAvailabilityBySpecialist: false,
        inactive: false,
      },
    };
  },
  mounted() {
    this.model = { ...this.model, ...this.value };
  },
  methods: {
    addDocument() {
      this.model.documents.push({
        documentName: null,
        documentDescription: null,
        isRequired: false,
        isAutoDelete: false,
      });
    },
    removeDocument(index) {
      this.model.documents.splice(index, 1);
    },
    async onSubmit() {
      if (!(await this.$refs.formRef.validate())) return;

      const request = !this.model.id
        ? _addCodeServiceType
        : _updateCodeServiceType;
      request(this.model)
        .then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('close');
          this.$emit('submitted');
        })
        .catch((error) => {
          this.ShowErrorToast(error.response.data.message);
        });
    },
  },
};
</script>
