<template>
  <div
    border-variant="light"
    class="card p-4"
  >
    <div class="row">
      <div class="col-md-10">
        <p>
          <b>{{ $t('office') }}</b> :
          <span class="text-primary font-weight-bold">
            {{ value.officeName }}
          </span>
        </p>
        <p>
          <b>{{ $t('town') }}</b> :
          <span class="text-primary font-weight-bold">
            {{ value.municipality }}
          </span>
        </p>
      </div>

      <div class="col-md-2">
        <action-button
          :actions="actions"
          class="float-right"
          :text="$t('actions')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'OfficeMonitorSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    'value.displays': {
      handler(newVal) {
        newVal.forEach((element) => {
          this.actions.push({
            label: element.name,
            iconClass: 'far fa-chevron-right p-2',
            iconColor: '#0F16DB',
            action: () => this.showDisplayPreview(element),
            visible: () => {
              return element.macAddressList != null;
            },
          });
        });
      },
      immediate: true,
    },
  },
  data() {
    return {
      actions: [],
    };
  },
  methods: {
    showDisplayPreview(kiosk) {
      this.$emit('on-show-preview', kiosk);
    },
  },
};
</script>

<style lang="scss" scoped></style>
