<template>
  <section>
    <content-header :title="$t('maintenanceOfTypeOfServicesByOffice')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('typeOfServicesByOfficeFilter')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getCodeServiceTypes(1)"
        />
      </div>
      <separator class="my-4" :text="$t('listOfTypeOfServicesByOffice')" />

      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            v-if="selectedFilters.location.length"
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="() => openCreateModal()"
            :text="$t('add')"
          />
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(item, index) in codeServiceTypes.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="item"
            :index="index"
            :index-in-edit-mode="indexInEditMode"
            @edit="indexInEditMode = $event"
            @on-delete="onDelete(item)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="codeServiceTypes.count > 0"
        class="my-4"
        :data="codeServiceTypes"
        :page-size="pageSize"
        @pagination-go-page="getCodeServiceTypes($event)"
        @pagination-rows-per-page="getCodeServiceTypes(pageIndex, $event)"
      />
      <custom-modal
        v-model="showCreateModal"
        size="md"
        :title="$t('maintenanceOfTypeOfServicesByOffice')"
      >
        <validation-observer ref="formRef" novalidate>
          <div class="row">
            <div class="col-md-12">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="serviceTypeId"
                  fieldtext="name"
                  fieldvalue="id"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  :label="$t('officeType')"
                  :options="collections.codeServiceTypes"
                />
              </validation-provider>
            </div>
            <div class="col-md-12 text-right mt-3">
              <base-filled-button
                bg-color="#707070"
                class="mx-0 float-left"
                icon-class="fas fa-ban"
                :on-click="() => (showCreateModal = false)"
                :text="$t('cancel')"
              />

              <button
                class="btn btn-outline-primary"
                variant="outline-primary"
                @click="onSubmit()"
              >
                <i class="far fa-save" /> {{ $t('save') }}
              </button>
            </div>
          </div>
        </validation-observer>
      </custom-modal>
      <DeleteModal
      v-model="showDeleteModal"
      error-message="nameIsNotCorrect"
      :item="selectedCodeServiceType"
      label="serviceName"
      @on-confirm="onConfirmDelete()"
    />
    </div>
  </section>
</template>
<script>
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader.vue';
import FilterManager from '@/components/FilterManager';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import CustomModal from '@/components/basics/modal/CustomModal';
import Separator from '@/components/Separator';
import SearchResult from './components/SearchResult.vue';
import CustomPagination from '@/components/CustomPagination.vue';
import {
  getCodeServiceTypesLocation as _getCodeServiceTypesLocation,
  addCodeServiceTypesByLocation as _addCodeServiceTypesByLocation,
  deleteCodeServiceTypesByLocation as _deleteCodeServiceTypesByLocation,
  getCodeServiceTypesList as _getCodeServiceTypesList,
} from '@/services/ServicesService';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getOrganizations as _getOrganizations } from '@/services/OrganizationService';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import DeleteModal from '@/components/DeleteModal';

export default {
  name: 'CodeServiceTypesLocationSearch',
  components: {
    contentHeader,
    SubHeader,
    FilterManager,
    CustomNotFound,
    Separator,
    SearchResult,
    CustomPagination,
    CustomModal,
    DeleteModal
  },
  data() {
    return {
      filters: {
        organizations: [],
        companies: [],
        locations: [],
      },
      selectedFilters: {
        organization: [],
        company: [],
        location: [],
        name: '',
      },
      collections: {
        codeServiceTypes: [],
      },
      codeServiceTypes: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      noResultsFound: false,
      pageSize: 10,
      showCreateModal: false,
      pageIndex: 1,
      indexInEditMode: null,
      serviceTypeId: null,
      selectedCodeServiceType: null,
      showDeleteModal: false
    };
  },
  async mounted() {
    await this.getOrganizations();
    await _getCodeServiceTypesList().then(
      (response) => (this.collections.codeServiceTypes = response.data),
    );
  },
  computed: {
    filtersConfig() {
      return [
        {
          rules: 'required',
          name: 'organization',
          label: this.$t('organization'),
          component: 'FilterMultiSelect',
          options: this.filters.organizations,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4',
          error: this.$t('invalidField'),
          initialValue: this.selectedFilters.organization,
          onChanged: this.onOrganizationChanged,
        },
        {
          rules: 'required',
          name: 'company',
          label: this.$t('company'),
          component: 'FilterMultiSelect',
          options: this.filters.companies,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4',
          error: this.$t('mustselectcompany'),
          initialValue: this.selectedFilters.company,
          onChanged: this.onCompanyChanged,
        },
        {
          rules: 'required',
          name: 'location',
          label: this.$t('localization'),
          component: 'FilterMultiSelect',
          options: this.filters.locations,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm-12 col-md-4 ',
          error: this.$t('invalidField'),
          initialValue: this.selectedFilters.location,
          onChanged: this.onLocationChanged,
        },
        {
          rules: '',
          name: 'name',
          label: this.$t('name'),
          component: 'BaseInput',
          placeholder: this.$t('name'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
      ];
    },
  },
  methods: {
    async getOrganizations() {
      await _getOrganizations()
        .then((response) => {
          this.filters.organizations = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getCompanies(organization) {
      await _getCompanies(organization)
        .then((response) => {
          this.filters.companies = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getLocalizations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onOrganizationChanged(value) {
      this.cleanRecords();
      this.selectedFilters.organization = [];
      this.selectedFilters.company = [];

      if (value) {
        this.selectedFilters.organization = [value];
        this.getCompanies(this.selectedFilters.organization[0].value);
      }
    },
    onCompanyChanged(value) {
      this.cleanRecords();
      this.selectedFilters.company = [];
      this.selectedFilters.location = [];
      this.filters.locations = [];
      if (value) {
        this.selectedFilters.company = [value];
        this.getLocalizations(this.selectedFilters.company[0].value);
      }
    },
    onLocationChanged(value) {
      this.cleanRecords();
      this.selectedFilters.location = [];
      if (value) {
        this.selectedFilters.location = [value];
      }
    },
    openCreateModal() {
      this.serviceTypeId = null;
      this.showCreateModal = true;
    },
    async getCodeServiceTypes(pageIndex = null, pageSize = null) {
      this.pageIndex = pageIndex || this.pageIndex;
      this.pageSize = pageSize || this.pageSize;

      const payload = {
        name: this.selectedFilters.name,
        locationConfigurationId: this.selectedFilters.location.length
          ? this.selectedFilters.location[0].id
          : null,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };
      this.indexInEditMode = null;

      await _getCodeServiceTypesLocation(payload).then(({ data }) => {
        this.codeServiceTypes = data;
        this.noResultsFound = !this.codeServiceTypes.count;
      });
    },
    cleanRecords() {
      this.codeServiceTypes = {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      };
      this.noResultsFound = false;
    },
    async onSubmit() {
      if (!(await this.$refs.formRef.validate())) return;

      await _addCodeServiceTypesByLocation({
        locationConfigurationId: this.selectedFilters.location[0].id,
        id: this.serviceTypeId,
      }).then(() => {
        this.ShowSuccessSaveToast();
        this.showCreateModal = false;
        this.getCodeServiceTypes();
      });
    },
    onDelete(item) {
      this.showDeleteModal = true;
      this.selectedCodeServiceType = item;
    },
    async onConfirmDelete() {
      await _deleteCodeServiceTypesByLocation({
        locationConfigurationId: this.selectedFilters.location[0].id,
        id: this.selectedCodeServiceType.id,
      }).then(() => {
        this.ShowSuccessToast(this.$t('successfullyDeleted'));
        this.showDeleteModal = false;
        this.getCodeServiceTypes();
        this.selectedCodeServiceType = null;
      });
    },
  },
};
</script>
