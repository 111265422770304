<template>
  <section>
    <content-header :title="$t('officeMonitorsMaintenance')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('officeMonitorsFilters')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getDisplays(1)"
        />
      </div>

      <separator
        class="my-4"
        :text="$t('listOfOfficeMonitors')"
      />

      <CustomNotFound
        v-if="noResultsFound"
        :text="$t('noResultsFound')"
      />
      <div class="row">
        <div
          v-for="(display, index) in displays.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="display"
            @on-show-preview="onShowPreview"
          />
        </div>
      </div>
      <custom-pagination
        v-if="displays.count"
        id="pgPager"
        :data="displays"
        :page-size="pageSize"
        @pagination-go-page="getDisplays($event)"
        @pagination-rows-per-page="getDisplays(pageIndex, $event)"
      />
      <preview-modal
        ref="preview-modal-ref"
        :title="previewTitle"
      />
    </div>

    <BigLoader v-if="loading" />
  </section>
</template>

<script>
import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import SearchResult from './components/SearchResult';
import FilterManager from '@/components/FilterManager';
import BigLoader from '@/components/BigLoader';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import IdentityPermission from '@/constants/IdentityPermission';
import Separator from '@/components/Separator';
import PreviewModal from '@/components/PreviewModal.vue';
import { getMunicipalities as _getMunicipalities } from '@/services/MunicipalityService';
import {
  getLocationList as _getLocationList,
  getOfficeTypes as _getOfficeTypes,
} from '@/services/LocationService';
import { getOfficeMonitors as _getOfficeMonitors } from '@/services/DisplayService';
import { DISPLAY } from '@/config';

export default {
  name: 'OfficeMonitors',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchResult,
    FilterManager,
    contentHeader,
    SubHeader,
    Separator,
    BigLoader,
    PreviewModal,
  },
  data() {
    return {
      previewTitle: null,
      selectedFilters: {
        officeType: [],
        municipality: [],
        location: '',
      },
      collections: {
        officeTypes: [],
        municipalities: [],
        locations: [],
      },
      displays: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      showDeleteModal: false,
      selectedStation: null,
      showCreateModal: false,
      loading: false,
    };
  },
  async mounted() {
    await _getOfficeTypes().then(
      (response) => (this.collections.officeTypes = response.data),
    );

    await _getMunicipalities({ stateCode: 'PR' })
      .then((response) => {
        this.collections.municipalities = response.data;
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));

    await _getLocationList().then(async (resp) => {
      this.selectedLocations = [];

      this.collections.locations = resp.data;
    });
  },
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'officeType',
          label: this.$t('officeType'),
          component: 'FilterMultiSelect',
          options: this.collections.officeTypes,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm',
          initialValue: this.selectedFilters.officeType,
          onChanged: this.onOfficeTypeChanged,
        },
        {
          rules: '',
          name: 'municipality',
          label: this.$t('town'),
          component: 'FilterMultiSelect',
          options: this.collections.municipalities,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm',
          initialValue: this.selectedFilters.municipality,
          onChanged: this.onMunicipalityChanged,
        },
        {
          rules: '',
          name: 'location',
          label: 'Nombre de la Oficina',
          component: 'FilterMultiSelect',
          options: this.collections.locations,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm',
          initialValue: this.selectedFilters.location,
          onChanged: this.onLocationChanged,
        },
      ];
    },
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
  },

  methods: {
    onLocationChanged(value) {
      this.selectedFilters.location = [];
      if (value) {
        this.selectedFilters.location = [value];
      }
    },
    onOfficeTypeChanged(value) {
      this.selectedFilters.officeType = [];
      if (value) {
        this.selectedFilters.officeType = [value];
      }
    },
    onMunicipalityChanged(value) {
      this.selectedFilters.municipality = [];
      if (value) {
        this.selectedFilters.municipality = [value];
      }
    },
    onShowPreview(display) {
      if (display.macAddressList && display.macAddressList != '') {
        const splitIndex = display.macAddressList.indexOf(',');

        // Show the modal
        this.previewTitle = display.name;

        this.$refs['preview-modal-ref'].show(
          `${DISPLAY.BASE_URL}?macAddress=${encodeURIComponent(
            display.macAddressList.slice(
              0,
              splitIndex == -1 ? display.macAddressList.length : splitIndex,
            ),
          )}&preview=1`,
        );
      }
    },
    async getDisplays(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        OfficeTypeId: this.selectedFilters.officeType.length
          ? this.selectedFilters.officeType[0].id
          : null,
        MunicipalityId: this.selectedFilters.municipality.length
          ? this.selectedFilters.municipality[0].id
          : null,
        LocationId: this.selectedFilters.location.length
          ? this.selectedFilters.location[0].id
          : null,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };
      await _getOfficeMonitors(payload)
        .then(({ data }) => {
          this.displays = data;
          this.filtered = true;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss">
#preview-modal-ref > .modal-dialog {
  margin: 0;
  margin-left: 8%;
}

#preview-modal-ref > .modal-dialog .modal-content {
  min-width: 1280px;
  min-height: 846px;
  overflow: hidden;
}

#frmPreviewContainer {
  width: 100%;
  height: 95%;
  position: absolute;
  overflow: hidden;
}
</style>
