<template>
  <section>
    <content-header :title="$t('maintenanceOfTypeOfServices')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('typeOfServicesFilter')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getCodeServiceTypes(1)"
        />
      </div>

      <separator
        class="my-4"
        :text="$t('listOfTypeOfServices')"
      />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="() => openFormModal()"
            :text="$t('create')"
          />
        </div>
      </div>
       <CustomNotFound
        v-if="noResultsFound"
        :text="$t('noResultsFound')"
      />
      <div class="row">
        <div
          v-for="(item, index) in codeServiceTypes.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="item"
            @edit="openFormModal(item)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="codeServiceTypes.count > 0"
        class="my-4"
        :data="codeServiceTypes"
        :page-size="pageSize"
        @pagination-go-page="getCodeServiceTypes($event)"
        @pagination-rows-per-page="getCodeServiceTypes(pageIndex, $event)"
      />
      <custom-modal
        v-model="showFormModal"
        :title="$t('maintenanceOfTypeOfServices')"
        size="lg"
      >
        <Form v-if="showFormModal" :value="serviceType" @close="showFormModal = false"  @submitted="getCodeServiceTypes()" />
      </custom-modal>
    </div>
  </section>
</template>
<script>
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader.vue';
import FilterManager from '@/components/FilterManager';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import Separator from '@/components/Separator';
import SearchResult from './components/SearchResult.vue';
import Form from './components/Form.vue';
import CustomPagination from '@/components/CustomPagination.vue';
import CustomModal from '@/components/basics/modal/CustomModal';
import {
  getCodeServiceTypes as _getCodeServiceTypes
} from '@/services/ServicesService';

export default {
  name: 'CodeServiceTypesSearch',
  components: {
    contentHeader,
    SubHeader,
    FilterManager,
    CustomNotFound,
    Separator,
    SearchResult,
    CustomPagination,
    Form,
    CustomModal
  },
  data() {
    return {
      selectedFilters: {
        name: '',
      },
      codeServiceTypes: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      noResultsFound: false,
      pageSize: 10,
      showCreateModal: false,
      pageIndex: 1,
      showFormModal: false,
      serviceType: null
    };
  },
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'name',
          label: this.$t('name'),
          component: 'BaseInput',
          placeholder: this.$t('name'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
      ];
    },
  },
  methods: {
    openFormModal(serviceType = null) {
      this.serviceType = serviceType;
      this.showFormModal = true;
    },
   async getCodeServiceTypes(pageIndex = null, pageSize = null) {
      this.pageIndex = pageIndex || this.pageIndex;
      this.pageSize = pageSize || this.pageSize;

      const payload = {
        name: this.selectedFilters.name,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getCodeServiceTypes(payload)
        .then(({ data }) => {
          this.codeServiceTypes = data;
          this.noResultsFound = !this.codeServiceTypes.count;
        })
    },
  },
};
</script>

